<script setup>
import {Head, usePage, Link, router} from '@inertiajs/vue3';
import Default from "@/Layouts/Default.vue";
import {computed, inject, ref} from "vue";

defineOptions({layout: Default})
defineProps({
    documents: Array,
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const documentsTableHeaders = [
    {title: '', key: 'type'},
    {title: 'Status', key: 'status'},
    {title: 'Nummer', key: 'number'},
    {title: 'Kunde', key: 'customer.name'},
    {title: 'Projekt', key: 'project'},
    {
        title: 'Datum', key: 'date', value: item => {
            return item.date ? `${new Date(item.date).toLocaleDateString(undefined, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            })}` : '-'
        }
    },
    {title: 'Summe', key: 'total'},
    {title: '', key: 'actions', sortable: false},
]

const newDocumentDialog = ref(false)
const duplicateDocumentLoading = ref(false)

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Zahlung'})
</script>

<template>
    <Head title="Zahlung"/>

    <v-container
        :fluid="true"
        class="overflow-hidden h-100 d-flex flex-column flex-nowrap"
    >
        <v-row
            v-if="user.permissions.includes('create_payments')"
            class="flex-grow-0 align-center"
        >
            <v-col class="d-flex align-center">
                <v-btn
                    v-if="false"
                    class="text-none"
                    color="primary"
                    variant="flat"
                    prepend-icon="mdi-plus"
                    text="Dokument"
                    @click="newDocumentDialog = true"
                />
                <v-spacer/>
                <Link
                    :href="route('payments.settings')"
                >
                    <v-icon icon="mdi-cog-outline"/>
                </Link>
            </v-col>
        </v-row>

        <v-container
            style="max-width: 1200px"
            class="h-100 d-flex flex-column flex-nowrap"
        >
            <v-row v-if="!documents?.length">
                <v-col class="d-flex flex-column flex-grow-1 align-center justify-center ga-3">
                    <h1>Noch keine Dokumente 😅</h1>
                    <img
                        :src="`/img/payments/no_documents.svg`"
                        style="height: 300px"
                        alt=""
                    >
                    <div>
                        <v-btn
                            color="primary"
                            prepend-icon="mdi-plus"
                            text="Erstes Dokument anlegen"
                            @click="newDocumentDialog = true"
                        />
                    </div>
                </v-col>
            </v-row>
            <v-card
                v-if="documents?.length"
                class="h-100 overflow-y-auto flex-grow-1"
            >
                <v-container
                    :fluid="true"
                    class="h-100 d-flex flex-column flex-nowrap"
                >
                    <v-row>
                        <v-col
                            style="overflow-y: scroll"
                        >
                            <v-data-table
                                :items="documents"
                                :items-per-page="0"
                                :headers="documentsTableHeaders"
                                @click:row="(_, {item}) => router.visit($route('payments.details', item.id))"
                            >
                                <template #item.type="{ item }">
                                    <div class="d-flex align-center ga-2">
                                        <v-avatar
                                            :icon="item.type === 'offer' ? 'mdi-handshake-outline' : item.type === 'invoice' ? 'mdi-invoice-text-outline' : undefined"
                                            variant="tonal"
                                        />

                                        <div class="font-weight-bold">
                                            {{
                                                item.type === 'offer' ? 'Angebot' : item.type === 'invoice' ? 'Rechnung' : null
                                            }}
                                        </div>
                                    </div>
                                </template>

                                <template #item.number="{ item }">
                                    {{ item.number || '-' }}
                                </template>

                                <template #item.project="{ item }">
                                    {{ item.project?.name || '-' }}
                                </template>

                                <template #item.total="{ item }">
                                    {{
                                        (item.total_amount / 100)?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })
                                    }} €
                                </template>

                                <template #item.status="{ item }">
                                    <v-chip
                                        v-if="item.status === 'draft'"
                                        text="Entwurf"
                                    />

                                    <div v-if="item.type === 'offer'">
                                        <v-chip
                                            v-if="item.status === 'sent'"
                                            color="green"
                                            text="Versandt"
                                        />
                                    </div>

                                    <div v-if="item.type === 'invoice'">
                                        <v-chip
                                            v-if="item.status === 'sent'"
                                            color="orange"
                                            text="Versandt"
                                        />
                                        <v-chip
                                            v-if="item.status === 'paid'"
                                            color="green"
                                            text="Bezahlt"
                                        />
                                        <v-chip
                                            v-if="item.status === 'overdue'"
                                            color="red"
                                            text="Überfällig"
                                        />
                                    </div>
                                </template>

                                <template #item.actions="{ item }">
                                    <v-menu v-if="user.permissions.includes('create_payments')">
                                        <template #activator="{ props }">
                                            <v-btn
                                                icon="mdi-dots-vertical"
                                                variant="text"
                                                v-bind="props"
                                            />
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                prepend-icon="mdi-content-duplicate"
                                                title="Duplizieren"
                                                @click="router.post(route('payments.duplicate', {paymentDocument: item.id}))"
                                            />

                                            <v-list-item
                                                v-if="item.type === 'offer'"
                                                prepend-icon="mdi-invoice-text-plus-outline"
                                                title="Rechnung erstellen"
                                                @click="router.post(route('payments.duplicate', {paymentDocument: item.id, targetType: 'invoice'}))"
                                            />
                                        </v-list>
                                    </v-menu>
                                </template>

                                <template #bottom/>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>

            <!-- New Document Dialog -->
            <v-dialog
                v-model="newDocumentDialog"
                transition="dialog-bottom-transition"
                width="auto"
            >
                <v-card>
                    <div class="d-flex ga-5 pa-5">
                        <v-btn
                            class="d-flex flex-column ga-3 text-h5"
                            prepend-icon="mdi-handshake-outline"
                            text="Angebot"
                            color="primary"
                            width="150px"
                            height="150px"
                            @click="router.visit(route('payments.new', { type: 'offer' }))"
                        />

                        <v-btn
                            class="d-flex flex-column ga-3 text-h5"
                            prepend-icon="mdi-invoice-text-outline"
                            text="Rechnung"
                            color="primary"
                            width="150px"
                            height="150px"
                            @click="router.visit(route('payments.new', { type: 'invoice' }))"
                        />
                    </div>
                </v-card>
            </v-dialog>

            <!-- Download Progress Dialog -->
            <v-dialog
                v-model="duplicateDocumentLoading"
                :scrim="true"
                :persistent="true"
                width="auto"
            >
                <v-card
                    color="primary"
                >
                    <v-card-text>
                        Dokument wird erstellt...
                        <v-progress-linear
                            :indeterminate="true"
                            color="white"
                            class="mb-0 mt-3"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-container>
</template>
